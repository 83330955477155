.dialog {
    width: auto;
    padding: 0px 0px 7px 0px;
}

.loading-ctr-group{
    display: block !important;
    margin-top: 40px;
    width: 100%;
}

.dialog-footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 10px;
}

.dialog-footer2{
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    margin-right: 10px;
}

.dialog-footer3{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 10px;
}

.dialog-footer button{
    margin-left: 5px
}

.groupDialog-field{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
}   

.groupDialog-field2{
    margin-top: 10px;
}

.color-picker-footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 7px;
    margin-right: 7px;
}