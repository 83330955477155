/* button:focus, span:focus {
  outline: none;  
}

button:focus-visible, span:focus-visible {
  outline: auto;
}

button:focus:not(:focus-visible) {
  background: white; 
} 
*/

.App {
  text-align: center;
  display: flex;  
  flex-direction: column;
}

.nav-row {
  height: 6vh;
  background-color: darkcyan;
  width: 100%;  
}

.body-row{
  /* height: 94vh; */
  display: flex;
  flex-direction: row;
}

.left-nav{
  /* min-width: 200px;
  background-color: lightgray   */
}

.app-body {
  background-color: #fff;  
  /* width: 100%;
  height: 100%; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  height: 100vh;
}