.confirm-table-header {
    position: sticky;
    top: 0;
    background-color: #edeff2;
    z-index: 1;
}

.confirm-table-header th:not(:last-child) {
    border-right: none;
}

.confirm-dialog {
    width: auto;
    max-width: 80%;
    min-width: 400px;
}