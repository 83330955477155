.sample-file-upload {
    padding: 10px;
}
  
.file-select, .sheet-select {
    margin-bottom: 20px;
}
  
.file-select label, .sheet-select label {
    display: block;
    margin-bottom: 10px;
}
  
.file-name {
    margin-left: 10px;
    font-style: italic;
}
  
.sheet-dropdown select {
    margin-right: 10px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}


.sheet-select {
    display: flex;
    align-items: center;
    gap: 10px;
}

.sheet-select label {
    margin-bottom: 0;
}

.sheet-select select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}
  
.supported-formats {
    font-family: 'Arial', sans-serif;
    font-size: 0.9em;
    font-weight: normal;
    color: #666;
    margin-left: 5px;
    letter-spacing: 0.5px;
}