.view-toolbar-ctr {
    display: flex;
}
.toolBar {
    display: flex;
  }
.toolBarCount {
    margin-left: auto;
    padding: 3px;
    font-size: smaller;
}
.toolBarCount div {
  font-size: small;
}
.viewCount { 
    float:right ;
    text-align: right;
    color: #0f0203;
    font-size: medium;
    padding-top: 3px;
}

.dendrogram-slider{
    margin: 0px 20px 0px 20px;
    width: 184px;
}

.dendrogram-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.icon-cross {
  margin: 4px;
  cursor: pointer;
}

.switch-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
}