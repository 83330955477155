.input-ctr {  
  margin: 5px;
}

.input-ctr .errorText {
  color: #cd4246;
  font-size: small;
}

.bp4-label{
  max-width: 400px
}

.inline-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.control-ctr{
  margin-left: 5px;
}
table {
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* margin: 0 auto;   */
  white-space: nowrap;
}
table th, td {
  /* border: 1px solid black; */
  margin: 0px 0px;
  padding: 5px 5px;
}
.bulkInsert{
  color: #250d92;
  font-size: small;
}
.bulkInsertError{
  color: #cd4246;
  font-size: small;
}
.label-container {
  display: inline-flex;
  align-items: center;
}
.required-asterisk {
  color: red;
  margin-left: 2px;
  position: relative;
  top: -1px;
}
