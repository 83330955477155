body {
  height: 100vh;  
  margin: 0px;
}

.grid-wrapper{
  height: 100vh;
  display: grid;
  grid-template-areas: 'header header'
                       'nav toolbar'
                       'nav main';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto 1fr;
}

.grid-header {
  grid-area: header;
}

.grid-left-nav {
  grid-area: nav;
}

.grid-toolbar{
  grid-area: toolbar;
}

.grid-main{
  grid-area: main;
  overflow-y: auto;
}