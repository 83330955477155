.round-checkbox {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font-size: 1em;
    width: 1em;
    height: 1em;
    border: 1px solid #ccc;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }
  
  .round-checkbox:checked {
    background-color: blue;
    border-color: blue;
  }
  
  .round-checkbox:checked::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    background-color: white;
  }

 .cellContainerStyle {
    width: 100%;
    height: 100%;
 }

 .outerBorder-checkbox__pipeline__publish {
  width: 40px; 
  height: 20px;
 }

 .innerRegion-pipeline, .innerRegion-publish {
  width: 20px; 
  height: 20px;
 }

 .tool-tip-grid-headers{
  width: 100%;
 }

 .bp5-popover-transition-container{
  z-index: 20;
 }