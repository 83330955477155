
.pn-datatable {

}

.pn-datatable table {
  /* border: 1px solid lightgray; */
}

.pn-datatable tbody {
  border-bottom: 1px solid lightgray;
  /* overflow-y: auto; */
}

.pn-datatable th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
}

.pn-datatable tbody tr {
  cursor: pointer;
}

.pn-datatable tbody tr:hover {
  background-color: aliceblue;
}

.pn-datatable-row-selected {
  background: #8ABBFF !important;
}


.pn-datatable tfoot {
  color: gray;
}

.pn-datatable tfoot th {
  font-weight: normal;
}
