.form-ctr{
  display: flex;
  flex-direction: column;
}

.form-body{
  display: flex;
  flex-direction: column !important;
}

.form-row{
  display: flex;
  flex-direction: row;
}

.form-column{
  display: flex;
  flex-direction: column;
}

.form-control{
  padding:5px
}

.backgroundStyle {
    gap: 0.5rem;
    padding: 0.5rem;
    border-color: #7f81a2;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    background: rgba(0, 75, 183, 0.2);
}

.dialog-body2 {
  padding: 5px;
}

.dialog-body3 {
  position: absolute;
  top: 46%;
  left: 43%;
  transform: translate(-50%, -50%);
}

.form-row2 {
display: flex;
justify-content: space-between;
align-items: flex-start;
margin-bottom: 1em;
}

.form-label2 {
flex-basis: 40%;
padding-right: 2em;
}

.form-value2 {
flex-basis: 60%;
}

.form-value2 ul {
  margin-block-start: 0em;
  padding-inline-start: 20px;
}

.button-row2 {
display: flex;
justify-content: flex-end;
margin-top: 20px;
}

.pn-form2 {
padding: 10px;
}

.display-linebreak {
  margin-bottom: 25px;
  overflow: auto;
}

