h1::after{
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background: #000;
    transition: transform 0.2s ease-in-out;
    transform: scale(0);
    position: relative;
    bottom: -6px;
    /* transform: translate(-50%, -50%); */
}
h1:hover::after{
    transform: scale(1);
}