.comparison-dialog {
    width: auto;
    max-width: 90%;
}

.comparison-button {
    margin-top: 15px;
    margin-bottom: 15px; 
    margin-left: 83px; 
    border-radius: 6px; 
    width: 200px;
}

.table-div {
    max-height: 422px; 
    overflow-y: auto;
}

.select-th {
    background-color:#edeff2;
    border-color:#edeff2; 
    position: sticky;
    top: 0
}

.header {
    color:white !important;
    background-color:#4472c4;
    border-color:white;
    position: sticky;
    top: 0;
}

.header-trash {
    display: table-cell;
    background-color:#edeff2;
    border-color:#edeff2;
    position: sticky;
    top: 0;
}

.select-column {
    display:block;
    width:83px;
    background-color:#edeff2; 
    border-color:#edeff2;
    border-top-color:#edeff2;
    box-shadow: none !important;
}

.column {
    border-color:white;
}

.column-trash {
    background-color:#edeff2;
    border-color:#edeff2;
    box-shadow: none !important;
}

.loading-ctr-comparison{
    display: block !important;
    margin-top: 20px;
    width: 100%;
}

.scrollable-groups {
    overflow-y: auto;
    min-height: 31px;
    max-height: 132px;
}

.scrollable-comparedSamples {
    overflow-y: auto;
    max-height: 367px;
}